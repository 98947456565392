//function for login modal
$(function () {
    //close modal when is asked
    $('.link').click(function () {
        $('#loginModal').modal('hide')
    });
    $(document).on('submit', '#formLogin', function (e) {
        e.preventDefault();
        $('input+small').text('');
        $('input').parent().removeClass('has-error');
        $('#statut-message').removeClass('alert-danger hidden').addClass('alert-success').html('Connection en cours ...');
        $.ajax({
            method: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize(),
            success: function (response) {
                window.location.reload();
            },
            error: function (jqXHR, textStatus, errorThrown) {
                var error = jqXHR.responseJSON;
                $('#statut-message').removeClass('alert-success hidden').addClass('alert-danger').html(error.error.message);
            }
        })
    });
})

//function for register modal
$(function () {
    //close modal when is asked
    $('.link').click(function () {
        $('#registerModal').modal('hide')
    });
    $(document).on('submit', '#formRegister', function (e) {
        e.preventDefault();
        $('input+small').text('');
        $('input').parent().removeClass('has-error');
        $('.alert-success').removeClass('hidden');
        $.ajax({
            method: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize(),
            success: function (data) {
                window.location.reload();
            },
            error: function (error) {
                $.each(error.responseJSON, function (key, value) {
                    $('.alert-success').addClass('hidden');
                    var input = '#formRegister input[name=' + key + ']';
                    $(input + '+small').text(value);
                    $(input).parent().addClass('has-error');
                });
            }
        });
    });
})

//function for password modal
$(function () {
    //close modal when is asked
    $('.link').click(function () {
        $('#passwordModal').modal('hide')
    });
    $(document).on('submit', '#formPassword', function (e) {
        e.preventDefault();
        $('input+small').text('');
        $('input').parent().removeClass('has-error');
        $('.alert-success').removeClass('hidden');
        $.ajax({
            method: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize(),
            success: function (data) {
                $('div.PasswordResetContent').addClass('hidden');
                $('div.successPasswordReset').removeClass('hidden');
            },
            error: function (jqXHR) {
                var error = jqXHR.responseJSON;
                if (error.error.code === 404) {
                    $('#statut-message-password').removeClass('hidden').addClass('alert-danger').html(error.error.message);
                } else {
                    $.each(error, function (key, value) {
                        var input = '#formPassword input[name=' + key + ']';
                        $(input + '+small').text(value);
                        $(input).parent().addClass('has-error');
                    });
                }
            }
        });
    });
});

//function daterange input daterange
$(function () {
    $('input[name="daterange"]').daterangepicker({
        locale: {
            format: 'DD/MM/YYYY'
        },
        startDate: $('#searchStartDate').val(),
        endDate: $('#searchEndDate').val()
    });
});
